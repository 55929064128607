import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">社宅公共藝術咖啡桌 / 第二輪</h2>
          <h1 className="main_title">#回饋與討論</h1>
        </div>


        <article className="page_content blog_single_post_content">

          <section className="row_area_s">
            <div className="column_5">
              <div className="context">
                <h4>殷寶寧 </h4>
                <p>殷寶寧以下列幾點作為回應下半場論述的發表。</p>
                <p>第一點，公共藝術機制執行的很多問題都是公部門的公共弊端，延續「法人」這點做為機制執行的解決方法之一，可能需要回到母法去處理。</p>
                <p>第二點，城市策略應為大架構小策略，以大的架構方向，包以在地的執行策略，回應不同的地方需求。也希望藝術家藉由主動倡議的過程，提出自己想要的概念。</p>
                <p>第三點，講述永續性的同時，須注意時間點的推移，不要忘記未來性。</p>
                <p>第四點，社會面向的議題應以有效去解決問題而非簡化的救濟，同時回應在一個健全的資本生態鏈，不要害怕談論營利，將有形無形的資本都計算進去，以好的設計機制來操作。</p>
                <p>第五點，公共藝術發生在真實的生活場景，必須思考每個地方不同的社會議題和需求，將公共藝術視為政策上的活棋。這樣議題挖掘公共藝術是一種社會設計，透過各種有創意藝術的方式去弭平社會問題，並強化公共性價值。 </p>

                <h4>林靜娟 </h4>
                <p>林靜娟認為公共藝術不再只是物件，而是建立在人的關懷、居民認同之上。設計上，應關乎生態環境、城市策略等，舉築空間計畫為例，應該與永續社區經營一起思考、一起規劃。</p>
                <p>如華山綠工場的藝術行動，創造出社區認同，包含人與人、人與社區的連結，效果比許多永久性裝置藝術來得更好。在公共藝術之中，藝術家應跳脫以自己為核心，消除人與人的邊界，而非舉辦制式的民眾參與。</p>
                <p>論及永續性，她提出面對社宅住民的流動，加上時代變動的加乘，一次性的計畫裝置等可能無法顧及往後變化。應設立長期機制，非一次使用完這樣的金額。而如何去面對非同溫層的人，面對不同的看法，也是一項課題。 </p>
              </div>
            </div>
          </section>

        </article>

        <div className="page_footer">
          <Link to="/blog/post_3_1" className="next_page">
            <div className="next_title">社宅公共藝術未來式</div>
            <div className="next_owner">郭恆成</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
